<template>
  <div class="view-portofoliu">

    <div id="portofoliu">
        <div v-for="(item, index) in portofolius" :key="index" class="projects" @click="showProjectModal(index)">
          <div class="project-image">
            <img :src="getThisImg(index)" :alt="'build image'" contain/>
          </div>
          <div class="project-description">{{ item.description }}</div>
        </div>
    </div>

    <ProjectModal 
      v-show="isProjectModalVisible"
      @close="closeProjectModal"
    >
      <template v-slot:projectDescription>
        {{ selectedProject.description }}
      </template>

      <template v-slot:allProjectImages>
        <div v-for="(itemProject, indexPhoto) in selectedProject.allImgs" :key="indexPhoto" class="projects">
          <div class="project-image">
            <img :src="getThisOtherImgs(indexPhoto)" :alt="'build image'" contain @click="showProjectPhotoModal(indexPhoto)"/>
          </div>
          <div class="project-description">{{ itemProject.description }}</div>
        </div>
      </template>

    </ProjectModal>

    <ProjectPhotoModal 
      v-show="isProjectPhotoModalVisible"
      @close="closeProjectPhotoModal"
    >

      <template v-slot:ProjectImage>
        <div class="project-photo">
          <div class="project-image">
            <img :src="selectedPhoto" :alt="'build image'" contain/>
          </div>
        </div>
      </template>

    </ProjectPhotoModal>

  </div>
</template>

<script>
  import ProjectModal from '../components/ProjectModal.vue';
  import ProjectPhotoModal from '../components/ProjectPhotoModal.vue';

  export default {
    name: 'AppHome',
    components: {
      ProjectModal,
      ProjectPhotoModal
    },
    data () {
      return {
        portofolius: [
            {
                dirName: 'PIC',
                img: '2_pic_s.png',
                description: 'Locuință unifamilială P+M cu anexă tehnică și bucătărie de vară',
                fullDescription: "",
                allImgs: ['1_pic_s.png', '2_pic_s.png', '3_pic_s.png', '4_pic_s.png', '5_pic_s.png'],
            },
            {
                dirName: 'BIC',
                img: '1_bic_s.png',
                description: 'Locuință unifamilială P+M cu regim de înălțime D+P+1E',
                fullDescription: "",
                allImgs: ['1_bic_s.png', '2_bic_s.png', '3_bic_s.png', '4_bic_s.png', '5_bic_s.png'],
            },
            {
                dirName: 'CTRGD',
                img: '3_ctrgd_s.png',
                description: 'Locuință unifamilială D+P+E',
                fullDescription: "",
                allImgs: ['1_ctrgd_s.png', '2_ctrgd_s.png', '3_ctrgd_s.png', '4_ctrgd_s.png', '5_ctrgd_s.png'],
            },
            {
                dirName: 'PAGS',
                img: '2_pags_s.png',
                description: 'Piață agroalimentară',
                fullDescription: "",
                allImgs: ['1_pags_s.png', '2_pags_s.png', '3_pags_s.png', '4_pags_s.png', '5_pags_s.png', '6_pags_s.png', '7_pags_s.png'],
            },
            {
                dirName: 'BLCO',
                img: '10_blco_s.png',
                description: 'Bloc de locuinte colective, parcare subterana, gradinita la parter',
                fullDescription: "",
                allImgs: ['1_blco_s.png', '2_blco_s.png', '3_blco_s.png', '4_blco_s.png', '5_blco_s.png', '6_blco_s.png', '7_blco_s.png',
                            '8_blco_s.png', '9_blco_s.png', '10_blco_s.png', '11_blco_s.png', '12_blco_s.png', '13_blco_s.png', '14_blco_s.png', '15_blco_s.png',
                            '16_blco_s.png'],
            },
            {
                dirName: 'SNNGO',
                img: '1_snngo_s.png',
                description: 'hypermarket',
                fullDescription: "",
                allImgs: ['1_snngo_s.png', '2_snngo_s.png', '3_snngo_s.png', '4_snngo_s.png'],
            },
        ],
        isProjectModalVisible: false,
        isProjectPhotoModalVisible: false,
        selectedPhoto: null,
        selectedProject: {
          index: null,
          description: null,
          allImgs: null,
        }
        
      }
    },
    methods: {
        getThisImg(i) {
            if (this.portofolius[i] && this.portofolius[i].img) {
                return require('../assets/img/projects/' + this.portofolius[i].dirName + '/' + this.portofolius[i].img);
            }
        },
        getThisOtherImgs(index) {
            if (this.selectedProject.allImgs && this.selectedProject.allImgs[index]) {
                return require('../assets/img/projects/' + this.portofolius[this.selectedProject.index].dirName + '/' + this.selectedProject.allImgs[index]);
            }
        },
        showProjectModal(index) {
          this.selectedProject = this.portofolius[index];
          this.selectedProject.index = index;
          this.isProjectModalVisible = true;
        },
        closeProjectModal() {
          this.isProjectModalVisible = false;
        },
        showProjectPhotoModal(index) {
          this.selectedPhoto = this.getThisOtherImgs(index);
          this.isProjectPhotoModalVisible = true;
        },
        closeProjectPhotoModal() {
          this.isProjectPhotoModalVisible = false;
          this.selectedProject.photoIndex = null;
        },
    },
  }
</script>

<style scoped>
  
  .view-portofoliu {
    height: 81vh;
    margin-top: 30px;
    margin-left: 10px;
    overflow-y: auto;
  }

  #portofoliu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .projects {
    margin: 10px;
    padding: 5px;
    background-color: antiquewhite;
    border-radius: 10px;
    width: 30%;
    border: 3px solid antiquewhite;
    cursor: pointer;
  }

  .projects .project-image {
    border-radius: 10px;
  }

  .projects .project-image img {
    max-width: 100%;
    z-index: -1;
  }

  .projects .project-description {
    margin-top: 5px;
    font-size: 14pt;
    font-style: italic;
    font-weight: bold;
  }

  .project-photo {
    margin: 10px;
    padding: 5px;
    background-color: antiquewhite;
    border-radius: 10px;
    border: 3px solid antiquewhite;
    cursor: pointer;
  }

  .project-photo img {
    max-width: 100%;
    z-index: -1;
  }

  @media screen and (max-width: 1366px) {
  
    .projects {
      width: 90%;
    }

    .project-top img {
      width: 90% !important;
    } 
  
  }

</style>



